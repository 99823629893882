// Add a new query
export default (state, action) => {
  let currentQueries = state.urlResolver ? state.urlResolver : {};
  let newQueries = { ...currentQueries };

  if (action.identifier === action.data.url_key) {
    newQueries[action.identifier] = { data: action.data, key: action.key };
  }

  return Object.assign({}, state, { urlResolver: newQueries });
};
