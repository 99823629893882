/**
 * Messages integrations with Redux following the ducks standard
 */

import messagesReducer from "./reducer";

export { default as queriesTypes } from "./types";
export { default as queriesActions } from "./actions";
export { default as queriesInitialState } from "./initialState";
export { default as queriesMiddleware } from "./middleware";
export default messagesReducer;

// End
